




























import { Component, Vue } from 'vue-property-decorator'
import Logo from '@/components/Logo.vue'

@Component({
  components: {
    Logo
  }
})
export default class SelectStoreError extends Vue {}
